<template>
    <v-row>
        <v-dialog v-model="dialog" scrollable>
            <v-card outlined max-width="575">
                <v-card-title class="text-h5">{{tituloDialog}} - {{dadosDialog}}</v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>...</th>
                                <th>...</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template v-if="!rede">
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-row class="mb-n12">
                                <v-col class="pl-2 pr-1 pl-lg-3 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtinicio"
                                        label="Data inicial"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col class="px-1 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtfim"
                                        label="Data final"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-bottom-sheet class="d-inline-flex d-sm-none" v-model="sheet">
                                    <v-sheet class="text-center" height="100%">
                                        <v-row class="pa-3">
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtinicio"
                                                    label="Data inicial"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtfim"
                                                    label="Data final"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="sheet = !sheet"
                                                >Cancelar</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="filtrar(0), sheet = !sheet"
                                                >Filtrar</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-bottom-sheet>
                                <v-col class="px-1 d-flex">
                                    <v-select
                                        class="d-inline-flex d-sm-none"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                        append-outer-icon="mdi-menu"
                                        @click:append-outer="sheet = !sheet"
                                    ></v-select>
                                    <v-select
                                        class="d-none d-sm-block"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                    ></v-select>
                                    <v-btn
                                        :disabled="carregando"
                                        class="mx-2 mt-1"
                                        color="primary"
                                        elevation="0"
                                        fab
                                        x-small
                                        @click="filtrar(0)"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <span
                        class="text-caption text--disabled pl-3"
                    >* Atualizado em: 10:52:34 14/12/2021</span>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Meta de visitas">
                            <template>700</template>
                        </CardDashboard>
                    </v-col>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Visitas realizadas">
                            <template>532</template>
                        </CardDashboard>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Visitas realizadas por filial</v-card-title>
                    <v-card-text>
                        <GraficoBarDashboard
                            :dataSeries="[
                                {name: 'Visitas realizadas', data: ['37', '42', '59', '12', '71', '37', '42', '59', '12', '71', '37', '42', '59', '12', '71', '37', '42', '59', '12', '71', '37', '42', '59', '12', '71', '37'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Meta de visitas', data: ['72', '35', '81', '78', '17', '27', '29', '25', '78', '26', '27', '35', '81', '19', '17', '27', '35', '81', '78', '26', '72', '35', '25', '78', '17', '27'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :categories="['Matriz', 'Pitanga', 'São João do Ivaí', 'Castelo', 'Cândido de Abreu', 'Apucarana', 'Jardim Alegre', 'Manoel Ribas', 'Faxinal', 'Campo Mourão', 'Cianorte', 'Paranavaí', 'Telemaco Borba', 'Roncador', 'Guarapuava', 'Palmital', 'Ibiporã', 'São Mateus do Sul', 'São João do Triunfo', 'Pontal do Paraná', 'Mandaguari', 'Astorga', 'Cambé', 'Reserva', 'Rolândia', 'Maringá']"
                            titleY
                            :horizontal="false"
                            height="450"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
        <template v-else>
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-row class="mb-n12">
                                <v-col class="pl-2 pr-1 pl-lg-3 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtinicio"
                                        label="Data inicial"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col class="px-1 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtfim"
                                        label="Data final"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-bottom-sheet class="d-inline-flex d-sm-none" v-model="sheet">
                                    <v-sheet class="text-center" height="100%">
                                        <v-row class="pa-3">
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtinicio"
                                                    label="Data inicial"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtfim"
                                                    label="Data final"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="sheet = !sheet"
                                                >Cancelar</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="filtrar(0), sheet = !sheet"
                                                >Filtrar</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-bottom-sheet>
                                <v-col class="px-1 d-flex">
                                    <v-select
                                        class="d-inline-flex d-sm-none"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                        append-outer-icon="mdi-menu"
                                        @click:append-outer="sheet = !sheet"
                                    ></v-select>
                                    <v-select
                                        class="d-none d-sm-block"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                    ></v-select>
                                    <v-btn
                                        :disabled="carregando"
                                        class="mx-2 mt-1"
                                        color="primary"
                                        elevation="0"
                                        fab
                                        x-small
                                        @click="filtrar(0)"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <span
                        class="text-caption text--disabled pl-3"
                    >* Atualizado em: 10:52:34 14/12/2021</span>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Meta de visitas">
                            <template>700</template>
                        </CardDashboard>
                    </v-col>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Visitas realizadas">
                            <template>532</template>
                        </CardDashboard>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Meta de visitas por vendedor</v-card-title>
                    <v-card-text>
                        <GraficoBarDashboard
                            :dataSeries="[
                                {name: 'Visitas realizadas', data: ['28', '45', '57', '33', '79'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Meta de visitas', data: ['42', '37', '67', '27', '25'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :categories="['José', 'Maria', 'João', 'Lucas', 'Sônia']"
                            titleY
                            height="450"
                            :horizontal="false"
                            palette="palette1"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";

export default {
    components: { InputDatePicker, CardDashboard, GraficoBarDashboard },
    name: "FilialDashboardVisitas",
    data: () => ({
        sheet: false,
        rede: true,
        dialog: false,
        tituloDialog: "",
        dadosDialog: {},
        carregando: false,
        filiais: {},
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim: `${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getDate()}`,
            idempresa: 0,
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        dialogConsulta(titulo, dados) {
            this.dialog = true;
            this.tituloDialog = titulo;
            this.dadosDialog = dados;
        },
        filtrar() {
            if (this.busca.idempresa == 0) {
                this.rede = true;
            } else {
                this.rede = false;
            }
        },
        listarEmpresas() {
            return axios
                .post(`${this.backendUrl}empresa/listar`, {
                    limit: 9999,
                    offset: 0,
                    busca: null,
                })
                .then((res) => {
                    let rede = { idempresa: 0, empresa: "Rede" };
                    this.filiais = res.data;
                    this.filiais.lista = this.filiais.lista.filter((v) => {
                        return (
                            v.idfilial != 6 &&
                            v.idfilial != 12 &&
                            v.idfilial != 22 &&
                            v.idfilial != 25 &&
                            v.idfilial != 29 &&
                            v.idfilial != 32 &&
                            v.idfilial != 33 &&
                            v.idfilial != 34 &&
                            v.idfilial != 37
                        );
                    });
                    this.filiais.lista.unshift(rede);
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
    },
    created() {
        this.listarEmpresas();
    },
};
</script>

<style scoped>
</style>